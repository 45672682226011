import React from "react"
import { Helmet } from "react-helmet"


import page from './home'


const z =
  <link rel="stylesheet" type="text/css" href="https://bulma-css-bulmaplay.appseed.us/assets/css/core.css" />


const IndexPage = () =>
  <>
    <Helmet>
      <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,600" rel="stylesheet"/>
      <link href="https://cdnjs.cloudflare.com/ajax/libs/bulma/0.7.5/css/bulma.css" rel="stylesheet"/>
    </Helmet>

      {page()}
  </>





export default IndexPage
